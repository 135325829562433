// contractual-act-module.js

import { 
  SET_CONTRACTUAL_ACT_LIST,
  SET_CONTRACTUAL_ACT_BY_ID,
  SET_CONTRACTUAL_ACT_STATUS,
  SET_UPDATE_CONTRACTUAL_ACT,
  SET_ADD_CONTRACTUAL_ACT,
  CONTRACTUAL_ACT_DELETE,
  SET_ADD_CONTRACTUAL_ACT_FILE,
  SET_DEL_CONTRACTUAL_ACT_FILE
} from '@/constants'

import {
  getContractualActListService,
  editContractualActService,
  getContractualActByIdService,
  updateContractualActStatusService, 
  addContractualActService,
  deleteContractualActService,
  addContractualActFileService,
  delContractualActFileService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'
 
const contractualActModule = {
  state: {
    contractualActList: [],
    contractualActById: {},
    contractualActDetail: []
  },

  getters: {
    contractualActList: (state) => state.contractualActList,
    contractualActById: (state) => state.contractualActById,
    contractualActDetail: (state) => state.contractualActDetail
  },

  mutations: {
    [SET_CONTRACTUAL_ACT_LIST]: (state, payload) => {
      state.contractualActList = payload
    },
    [SET_CONTRACTUAL_ACT_BY_ID]: (state, payload) => {
      state.contractualActDetail = payload
    },
    [SET_CONTRACTUAL_ACT_STATUS]: () => { },
    [SET_UPDATE_CONTRACTUAL_ACT]: () => { },
    [SET_ADD_CONTRACTUAL_ACT]: () => { },
    [CONTRACTUAL_ACT_DELETE]: () => { },
    [SET_ADD_CONTRACTUAL_ACT_FILE]: () => { },
    [SET_DEL_CONTRACTUAL_ACT_FILE]: () => { }
  },

  actions: {
    getContractualActListApi: async ({ commit }, params = {}) => {
      try {
        const resp = await getContractualActListService(params)
        if (isSuccess(resp.status)) {
          commit(SET_CONTRACTUAL_ACT_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting contractual act list:', error)
        return false
      }
    },

    getContractualActIDApi: async ({ commit }, params = {}) => {
      try {
        const resp = await getContractualActByIdService(params)
        if (isSuccess(resp.status)) {
          commit(SET_CONTRACTUAL_ACT_BY_ID, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting contractual act:', error)
        return false
      }
    },

    updateContractualActStatus: async ({ commit }, { id, status }) => {
      try {
        const resp = await updateContractualActStatusService(id, status)
        if (isSuccess(resp.status)) {
          commit(SET_CONTRACTUAL_ACT_STATUS)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating contractual act status:', error)
        return false
      }
    },

    addContractualActApi: async ({ commit }, formData) => {
      try {
        const resp = await addContractualActService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_CONTRACTUAL_ACT)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding contractual act:', error)
        return false
      }
    },

    updateContractualActApi: async ({ commit }, formData) => {
      try {
        const resp = await editContractualActService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_CONTRACTUAL_ACT)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating contractual act:', error)
        return false
      }
    },

    deleteContractualActApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteContractualActService(id)
        if (isSuccess(resp.status)) {
          commit(CONTRACTUAL_ACT_DELETE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting contractual act:', error)
        return false
      }
    },

    addContractualActFileApi: async ({ commit }, formData) => {
      try {
        console.log(formData)
        const resp = await addContractualActFileService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_CONTRACTUAL_ACT_FILE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding file:', error)
        return false
      }
    },

    delContractualActFileApi: async ({ commit }, formData) => {
      try {
        console.log(formData)
        const resp = await delContractualActFileService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_DEL_CONTRACTUAL_ACT_FILE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting file:', error)
        return false
      }
    }
  }
}

export {
  contractualActModule
}