export const SET_EMAIL = 'setEmail'
export const SET_TOKEN = 'setToken'
export const SET_ISMEMBER = 'setIsMember'
export const CLEAR_SESSION = 'clearSession'
export const SET_ERRMESSAGE = 'setErrMessage'
export const SET_ACTIVEERROR = 'setActiveError'
export const SET_ACTIVEINFO = 'setActiveInfo'
export const SET_INFOMESSAGE = 'setInfoMessage'
export const SET_NAME = 'setName'
export const SET_LOADING = 'setLoading'
export const REQUEST_PROFILE = 'requestProfile'
/* knowledge */
export const SET_KNOWLEDGELIST = 'setKnowledgeList'
export const SET_KNOWLEDGEL_BY_ID = 'setKnowledgeById'
export const SET_KNOWLEDGE_STATUS = 'SET_KNOWLEDGE_STATUS'
export const SET_KNOWLEDGE = 'SET_KNOWLEDGE'
export const SET_UPDATE_KNOWLEDGE = 'SET_UPDATE_KNOWLEDGE'
export const SET_ADD_KNOWLEDGE = 'SET_ADD_KNOWLEDGE'
export const KNOWLEDGE_UPDATE_STATUS = 'knowledge-update-status'
export const KNOWLEDGE_ADD = 'knowledge-add'
export const KNOWLEDGE_EDIT = 'knowledge-edit'
export const KNOWLEDGE_DELETE = 'knowledge-delete'
export const ADD_KNOWLEDGE_DOC = 'ADD_KNOWLEDGE_DOC'
export const DEL_KNOWLEDGE_DOC = 'DEL_KNOWLEDGE_DOC'

/* news */
export const SET_NEWSLIST = 'setNewsList'
export const SET_NEWS_BY_ID = 'setNewsById'
export const SET_NEWS_STATUS = 'setNewsStatus'


/* FAQ */
export const SET_FAQLIST = 'setFaqList'
export const SET_FAQ = 'setFaq'
export const SET_FAQ_STATUS = 'setFaqStatus'
export const SET_UPDATE_FAQ = 'setUpdateFaq'
export const SET_ADD_FAQ = 'setAddFaq'

/* set register step */
export const SET_REGISTER_STEP = 'setRegisterStep'

/* Consultation */
export const GET_CONSULTATION = 'getConsultation'
export const SET_CONSULTATION = 'setConsultation'
export const SET_CONSULTATION_BY_ID = 'setConsultationById'
export const SET_CONSULTATION_LIST = 'setConsultationList'
export const SET_CONSULTATION_PROFILE_BUF = 'setConsultationProfileBuf'
export const SET_CONSULTATION_DETAIL_BUF = 'setConsultationDetailBuf'
export const SET_CONSULTATION_FILE_BUF = 'setConsultationFileBuf'
export const DEL_CONSULTATION = 'delConsultation'
export const SET_CONSULTATION_LOG = 'setConsultationLog'

/* Consultation type */
export const SET_CONSULTATION_TYPE_LIST = 'setConsultationTypeList'
export const SET_ADD_CONSULTATION_TYPE = 'setAddConsultationType'  
export const SET_EDIT_CONSULTATION_TYPE = 'setEditConsultationType'
export const SET_DELETE_CONSULTATION_TYPE = 'setDeleteConsultationType'
/* user */
export const SET_USER_ADDRESS = 'setUserAddress'

/* Litigation */
export const SET_LITIGATION_LIST = 'setLitigationList'
export const SET_LITIGATION_STATUS = 'SET_LITIGATION_STATUS'
export const SET_LITIGATION = 'SET_LITIGATION'
export const SET_LITIGATION_BY_ID = 'setLitigationById'
export const SET_UPDATE_LITIGATION = 'SET_UPDATE_LITIGATION'
export const SET_ADD_LITIGATION = 'SET_ADD_LITIGATION' 
export const SET_ADD_LITIGATION_FILE = 'SET_ADD_LITIGATION_FILE' 
export const SET_DEL_LITIGATION_FILE = 'SET_DEL_LITIGATION_FILE' 
export const LITIGATION_DELETE = 'litigationDelete'

export const SET_LITIGATION_TYPE_LIST = 'setLitigationTypeList'
export const LITIGATION_TYPE_DELETE = 'litigationTypeDelete'
export const SET_UPDATE_LITIGATION_TYPE = 'SET_UPDATE_LITIGATION_TYPE'
export const SET_ADD_LITIGATION_TYPE = 'SET_ADD_LITIGATION_TYPE' 

export const SET_LITIGATION_SUM_LIST = 'setLitigationSumList'


export const SET_UPDATE_LITIGATION_LOG = 'SET_UPDATE_LITIGATION'
export const SET_ADD_LITIGATION_LOG = 'SET_ADD_LITIGATION' 
export const SET_LITIGATION_STAFF_LIST = 'SET_LITIGATION_STAFF_LIST'
export const SET_LITIGATION_ACTIVE = 'setLitigationActive'
/* Contractual Acts */
export const SET_CONTRACTUAL_ACT_LIST = 'SET_CONTRACTUAL_ACT_LIST'
export const SET_CONTRACTUAL_ACT = 'SET_CONTRACTUAL_ACT'
export const SET_CONTRACTUAL_ACT_BY_ID = 'SET_CONTRACTUAL_ACT_BY_ID'
export const SET_CONTRACTUAL_ACT_STATUS = 'SET_CONTRACTUAL_ACT_STATUS'
export const SET_UPDATE_CONTRACTUAL_ACT = 'SET_UPDATE_CONTRACTUAL_ACT'
export const SET_ADD_CONTRACTUAL_ACT = 'SET_ADD_CONTRACTUAL_ACT'
export const CONTRACTUAL_ACT_DELETE = 'CONTRACTUAL_ACT_DELETE'
export const SET_ADD_CONTRACTUAL_ACT_FILE = 'SET_ADD_CONTRACTUAL_ACT_FILE'
export const SET_DEL_CONTRACTUAL_ACT_FILE = 'SET_DEL_CONTRACTUAL_ACT_FILE'