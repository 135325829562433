import {
  SET_REGULATION_LIST,
  SET_REGULATION_DETAIL,
  SET_REGULATION_CATEGORY_LIST,
  SET_REGULATION_TYPE_LIST,
  SET_REGULATION_STAFF_LIST,
  SET_REGULATION_ACTIVE
} from '@/constants'

import {
  getListService,
  viewService,
  insertService,
  editService,
  addCategoryService,
  editCategoryService,
  getCategoryService,
  deleteCategoryService,
  addRegulationTypeService,
  editRegulationTypeService,
  getRegulationTypeService,
  deleteRegulationTypeService,
  insertFileService,
  deleteFileService,
  insertLogService,
  editLogService,
  getStaffService,
  assignService,
  deleteAssignService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const regulationModule = {
  state: {
    regulationList: [],
    regulationDetail: null,
    categoryList: [],
    typeList: [],
    staffList: []
  },

  mutations: {
    [SET_REGULATION_LIST]: (state, payload) => {
      state.regulationList = payload
    },
    [SET_REGULATION_DETAIL]: (state, payload) => {
      state.regulationDetail = payload
    },
    [SET_REGULATION_CATEGORY_LIST]: (state, payload) => {
      state.categoryList = payload
    },
    [SET_REGULATION_TYPE_LIST]: (state, payload) => {
      state.typeList = payload
    },
    [SET_REGULATION_STAFF_LIST]: (state, payload) => {
      state.staffList = payload
    }
  },

  actions: {
    // Main CRUD Actions
    getRegulationList: async ({ commit }) => {
      try {
        const resp = await getListService()
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting regulation list:', error)
        return false
      }
    },

    viewRegulation: async ({ commit }, id) => {
      try {
        const resp = await viewService(id)
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_DETAIL, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error viewing regulation:', error)
        return false
      }
    },

    insertRegulation: async ({ commit }, data) => {
      try {
        const resp = await insertService(data)
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_ACTIVE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error inserting regulation:', error)
        return false
      }
    },

    editRegulation: async ({ commit }, { id, data }) => {
      try {
        const resp = await editService(id, data)
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_ACTIVE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error editing regulation:', error)
        return false
      }
    },

    // Category Actions
    getCategories: async ({ commit }) => {
      try {
        const resp = await getCategoryService()
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_CATEGORY_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting categories:', error)
        return false
      }
    },

    addCategory: async ({ commit }, data) => {
      try {
        const resp = await addCategoryService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding category:', error)
        return false
      }
    },

    editCategory: async ({ commit }, data) => {
      try {
        const resp = await editCategoryService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing category:', error)
        return false
      }
    },

    deleteCategory: async ({ commit }, id) => {
      try {
        const resp = await deleteCategoryService(id)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting category:', error)
        return false
      }
    },

    // Type Actions 
    getRegulationTypesApi: async ({ commit }) => {
      try {
        const resp = await getRegulationTypeService()
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_TYPE_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting types:', error)
        return false
      }
    },

    addRegulationTypeApi: async ({ commit }, data) => {
      try {
        const resp = await addRegulationTypeService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding type:', error)
        return false
      }
    },

    editRegulationTypeApi: async ({ commit }, data) => {
      try {
        const resp = await editRegulationTypeService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing type:', error)
        return false
      }
    },

    deleteRegulationTypeApi: async ({ commit }, id) => {
      try {
        const resp = await deleteRegulationTypeService(id)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting type:', error)
        return false
      }
    },

    // File Actions
    insertFile: async ({ commit }, data) => {
      try {
        const resp = await insertFileService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error inserting file:', error)
        return false
      }
    },

    deleteFile: async ({ commit }, data) => {
      try {
        const resp = await deleteFileService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting file:', error)
        return false
      }
    },

    // Log Actions
    insertLog: async ({ commit }, data) => {
      try {
        const resp = await insertLogService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error inserting log:', error)
        return false
      }
    },

    editLog: async ({ commit }, { id, data }) => {
      try {
        // alert('editLog');
        const resp = await editLogService(id, data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing log:', error)
        return false
      }
    },

    // Staff/Assignment Actions
    getStaff: async ({ commit }) => {
      try {
        const resp = await getStaffService()
        if (isSuccess(resp.status)) {
          commit(SET_REGULATION_STAFF_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting staff:', error)
        return false
      }
    },

    assign: async ({ commit }, data) => {
      try {
        const resp = await assignService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error assigning staff:', error)
        return false
      }
    },

    deleteAssign: async ({ commit }, data) => {
      try {
        const resp = await deleteAssignService(data)
        commit(SET_REGULATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting assignment:', error)
        return false 
      }
    }
  }
}

export { regulationModule }