import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const createXapiService = async (data) => {
  try {
    return await client.post(apiConstant.XAPI_CREATE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getListXapiService = async () => {
  try {
    return await client.get(apiConstant.XAPI_GET_LIST)
  } catch (error) {
    throw new Error(error) 
  }
}

export const loginXapiService = async (data) => {
  try {
    return await client.post(apiConstant.XAPI_LOGIN, data)
  } catch (error) {
    throw new Error(error)
  }
}