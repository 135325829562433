import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const addEvaluationScoreService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_SCORE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const addEvaluationService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const addEvaluationQuestionsService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_QUESTIONS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getQuestionsByTemplateIdService = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_QUESTIONS.replace(':id', id))
  } catch (error) {
    throw new Error(error)
  }
}

export const listScoresByTemplateService = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_LIST_SCORES.replace(':id', id))
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationDetailsService = async (userId, templateId) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_DETAILS.replace(':user_id', userId).replace(':id', templateId))
  } catch (error) {
    throw new Error(error)
  }
}

export const getListTemplateService = async () => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationTemplateService = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_TEMPLATE+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const editEvaluationService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_EDIT, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateEvaluationStatusService = async (data) => {
  try {
    return await client.put(apiConstant.EVALUATION_UPDATE_STATUS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteEvaluationQuestionsService = async (questionId) => {
  try {
    return await client.delete(`${apiConstant.EVALUATION_DELETE_QUESTIONS}/${questionId}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteEvaluationService = async (templateId) => {
  try {
    return await client.delete(`${apiConstant.EVALUATION_DELETE}/${templateId}`)
  } catch (error) {
    throw new Error(error)
  }
}