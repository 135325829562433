import Vue from 'vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

/* custom */
import '@/styles/custom.css';
import '@/styles/loading.css';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '',
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      name: '/',
      redirect: '/',
      component: () => import('@/pages/Main_NoLayout.vue'),
      children: [
        {
          path: '/',
          name: '',
          meta: {
          },
          component: () => import('@/pages/login/login.vue')
        },
        {
          path: 'login',
          name: 'login',
          meta: {
          },
          component: () => import('@/pages/login/login.vue')
        },
        {
          path: '/register/:id',
          name: 'register',
          meta: {
          },
          component: () => import('@/pages/register/register.vue')
        },
        {
          path: '/register_qrcode/:id',
          name: 'register-qrcode',
          meta: {
          },
          component: () => import('@/pages/register/register_qrcode.vue')
        },
      ]
    },
    
    {
    path: '/',
    name: 'home',
    redirect: '/',
    component: () => import('@/pages/Main.vue'),
    children: [
      // dashboard
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
        },
        component: () => import('@/pages/dashboard.vue')
      },
      {
        path: 'news',
        name: 'news',
        meta: {
        },
        component: () => import('@/pages/news/newsList.vue')
      },
      {
        path: 'news/create',
        name: 'news-create',
        meta: {
        },
        component: () => import('@/pages/news/newsCreate.vue')
      },
      {
        path: 'news/edit/:id',
        name: 'news-edit',
        meta: {
        },
        component: () => import('@/pages/news/newsEdit.vue')
      },
      {
        path: 'news/:id',
        name: 'news_detail',
        meta: {
        },
        component: () => import('@/pages/news/detail.vue')
      },

      {
        path: '/consultation/list',
        name: '/consultation-list',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationList.vue')
      },
      {
        path: '/consultation/type',
        name: 'consultation-type',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationEditType.vue')
      },
      {
        path: '/consultation/assignment/list',
        name: 'consultation-assignment',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationListAssignment.vue')
      },
      {
        path: '/consultation/assignment/:id',
        name: 'consultation-assignment',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationAssignment.vue')
      },
      {
        path: '/consultation/edit/:id',
        name: 'consultation-edit',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationEdit.vue')
      },

      {
        path: '/consultation/create',
        name: 'consultation-create',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate.vue')
      },


      // litigation
      {
        path: 'litigation/list',
        name: 'litigation',
        meta: {
        },
        component: () => import('@/pages/litigation/litigationList.vue')
      },
      {
        path: 'litigation/create',
        name: 'litigation-create',
        meta: {
        },
        component: () => import('@/pages/litigation/litigationCreate.vue')
      },
      {
        path: 'litigation/type',
        name: 'litigation-type',
        meta: {
        },
        component: () => import('@/pages/litigation/litigationType.vue')
      },
      {
        path: 'litigation/edit/:id',
        name: 'litigation-edit',
        meta: {
        },
        component: () => import('@/pages/litigation/litigationEdit.vue')
      },
      {
        path: 'litigation/summary',
        name: 'litigation-summary',
        meta: {
        },
        component: () => import('@/pages/litigation/litigationSummary.vue')
      },

      {
        path: 'contractual-act/list',
        name: 'contractual-act-list',
        meta: {
        },
        component: () => import('@/pages/contractualAct/contractualActList.vue')
      },
      {
        path: 'contractual-act/type',
        name: 'contractual-act-type',
        meta: {
        },
        component: () => import('@/pages/contractualAct/contractualActType.vue')
      },
      {
        path: 'contractual-act/group',
        name: 'contractual-act-group',
        meta: {
        },
        component: () => import('@/pages/contractualAct/contractualActGroup.vue')
      },
      {
        path: 'contractual-act/create',
        name: 'contractual-act-create',
        meta: {
        },
        component: () => import('@/pages/contractualAct/contractualActCreate.vue')
      },
      {
        path: 'contractual-act/edit/:id',
        name: 'contractual-act-edit',
        meta: {
        },
        component: () => import('@/pages/contractualAct/contractualActEdit.vue')
      },
      {
        path: 'regulations/list',
        name: 'regulations-list',
        meta: {
        },
        component: () => import('@/pages/regulations/regulationsList.vue')
      },
      {
        path: 'regulations/type',
        name: 'regulations-type',
        meta: {
        },
        component: () => import('@/pages/regulations/regulationsType.vue')
      },
      {
        path: 'regulations/create',
        name: 'regulations-create',
        meta: {
        },
        component: () => import('@/pages/regulations/regulationsCreate.vue')
      },
      {
        path: 'regulations/edit/:id',
        name: 'regulations-edit',
        meta: {
        },
        component: () => import('@/pages/regulations/regulationsEdit.vue')
      },
      {
        path: 'international-cooperation/list',
        name: 'international-cooperation-list',
        meta: {
        },
        component: () => import('@/pages/internationalCooperation/internationalCooperationList.vue')
      },
      {
        path: 'international-cooperation/type',
        name: 'international-cooperation-type',
        meta: {
        },
        component: () => import('@/pages/internationalCooperation/internationalCooperationType.vue')
      },
      {
        path: 'international-cooperation/create',
        name: 'international-cooperation-create',
        meta: {
        },
        component: () => import('@/pages/internationalCooperation/internationalCooperationCreate.vue')
      },
      {
        path: 'international-cooperation/edit/:id',
        name: 'international-cooperation-edit',
        meta: {
        },
        component: () => import('@/pages/internationalCooperation/internationalCooperationEdit.vue')
      },
      {
        path: 'evaluation',
        name: 'evaluation',
        meta: {
        },
        component: () => import('@/pages/evaluation/evaluationList.vue')
      },
      {
        path: 'evaluation/add/template',
        name: 'evaluation-add-template',
        meta: {
        },
        component: () => import('@/pages/evaluation/evaluationAddTpl.vue')
      },
      {
        path: 'evaluation/edit/template/:id',
        name: 'evaluation-edit_template',
        meta: {
        },
        component: () => import('@/pages/evaluation/evaluationEditTpl.vue')
      },
      {
        path: 'evaluation/score/:id',
        name: 'evaluation-score',
        meta: {
        },
        component: () => import('@/pages/evaluation/evaluationScore.vue')
      },
      {
        path: 'evaluation/score/detail/:user_id/:id',
        name: 'evaluation-score-detail',
        meta: {
        },
        component: () => import('@/pages/evaluation/evaluationScoreDetail.vue')
      },
      {
        path: 'knowledge-base',
        name: 'knowledge-base',
        meta: {
        },
        component: () => import('@/pages/knowledgeBase/knowledgeBaseList.vue')
      },
      {
        path: 'knowledge-base/create',
        name: 'knowledge-base-create',
        meta: {
        },
        component: () => import('@/pages/knowledgeBase/knowledgeBaseCreate.vue')
      },
      {
        path: 'knowledge-base/edit/:id',
        name: 'knowledge-base-edit',
        meta: {
        },
        component: () => import('@/pages/knowledgeBase/knowledgeBaseEdit.vue')
      },
      {
        path: 'knowledge-base/review/:id',
        name: 'knowledge-base-review',
        meta: {
        },
        component: () => import('@/pages/knowledgeBase/knowledgeBaseReview.vue')
      },



      
      {
        path: 'faq',
        name: 'faq',
        meta: {
        },
        component: () => import('@/pages/faq/faqList.vue')
      },
      {
        path: 'faq/create',
        name: 'faq/create',
        meta: {
        },
        component: () => import('@/pages/faq/faqCreate.vue')
      },  
      {
        path: 'faq/edit/:id',
        name: 'faq-edit',
        meta: {
        },
        component: () => import('@/pages/faq/faqEdit.vue')
      },  
      {
        path: 'xapi',
        name: 'xapi',
        meta: {
        },
        component: () => import('@/pages/xapi/xapiList.vue')
      },
      {
        path: 'xapi/history/:id',
        name: 'xapi-history',
        meta: {
        },
        component: () => import('@/pages/xapi/xapiHistory.vue')
      },

      {
        path: 'user',
        name: 'user',
        meta: {
        },
        component: () => import('@/pages/user/userList.vue')
      },
      {
        path: 'user/create',
        name: 'user-create',
        meta: {
        },
        component: () => import('@/pages/user/userCreate.vue')
      },
      {
        path: 'user/edit/:id',
        name: 'user-edit',
        meta: {
        },
        component: () => import('@/pages/user/userEdit.vue')
      },

      {
        path: 'member/list',
        name: 'member-list',
        meta: {
        },
        component: () => import('@/pages/member/memberList.vue')
      },
      {
        path: 'member/permission/group',
        name: 'member-permission-group',
        meta: {
        },
        component: () => import('@/pages/member/permissionsGroup.vue')
      },
      {
        path: 'member/create',
        name: 'member-create',
        meta: {
        },
        component: () => import('@/pages/member/memberCreate.vue')
      },
      {
        path: 'member/edit/:id',
        name: 'member-edit',
        meta: {
        },
        component: () => import('@/pages/member/memberEdit.vue')
      },

      {
        path: 'log',
        name: 'log',
        meta: {
        },
        component: () => import('@/pages/log/logList.vue')
      },


      
      
      
      

      //****************** */      
      {
        path: 'profile',
        name: 'profile',
        meta: {
        },
        component: () => import('@/pages/profile/profile.vue')
      },
      // {
      //   path: 'consultation/lists2',
      //   name: 'consultation/lists2',
      //   meta: {
      //   },
      //   component: () => import('@/pages/consultation/consultationList_.vue')
      // },
      {
        path: 'consultation/:id',
        name: 'member/detail',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationDetail.vue')
      },
      {
        path: '/consultation/create/step1',
        name: 'consultation/create/step1',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate1.vue')
      },
      {
        path: '/consultation/create/step2',
        name: 'consultation/create/step2',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate2.vue')
      },
      {
        path: '/consultation/create/step3',
        name: 'consultation/create/step3',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate3.vue')
      },
      {
        path: '/consultation/create/step4',
        name: 'consultation/create/step4',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate4.vue')
      },
   
    ]
  }
  ]
}

router.beforeEach((to, from, next) => {
  next()
})

export default router
