import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getAllConsultTopicService = async () => {
  try {
    return await client.get(apiConstant.CONSULTATION_GET_ALL_TOPICS)
  } catch (error) {
    throw new Error(error)
  }
}

export const addConsultTopicService = async (title) => {
  try {
    return await client.post(apiConstant.CONSULTATION_ADD_TOPIC, {
      title
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editConsultTopicService = async (id, title) => {
  try {
    return await client.put(`${apiConstant.CONSULTATION_EDIT_TOPIC}/${id}`, {
      title
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editConsultService = async (id, details, public_status) => {
  try {
    return await client.put(`${apiConstant.CONSULTATION_EDIT}/${id}`, {
      "details": details, 
      "public": public_status
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteConsultTopicService = async (id) => {
  try {
    return await client.delete(`${apiConstant.CONSULTATION_DELETE_TOPIC}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}