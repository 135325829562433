export const STATUS_SUCCESS = 'success'
export const STATUS_OK = 'ok'

export const apiConstant = {
  /* Auth */
  LOGIN: '/api/v1/auth/login',
  AUTH_AUTHENTICATE_TOKEN: '/api/v1/auth/authenticateToken',
  AUTH_REFRESH_TOKEN: '/api/v1/auth/authenticateRefreshToken',
  AUTH_GET_SERVER_CONFIG: '/api/v1/auth/getServerConfig',
  AUTH_DASHBOARD: '/api/v1/auth/dashboard',
  GET_FILE:'/api/v1/public/download',
  /* News */
  NEWS_GET_BY_ID: '/api/v1/public/news/getNewsById/',
  NEWS_GET_RELATED: '/api/v1/public/news/getRelateNew/',
  NEWS_GET: '/api/v1/public/news/getNews',
  NEWS_UPDATE_STATUS: '/api/v1/news/updateNewsStatus',
  NEWS_ADD: '/api/v1/News/addNews',
  NEWS_EDIT: '/api/v1/News/editNews',
  NEWS_DELETE: '/api/v1/News/deleteNews',
  NEWS_DELETE_IMAGE: '/api/v1/News/deleteNewsImage',

  /* Consultation */
  CONSULTATION_USER_REQUEST: '/api/v1/consult/userRequestConsultation',
  CONSULTATION_GET_BY_USER_ID: '/api/v1/consult/getConsultByUserId',
  CONSULTATION_GET_USER_BY_ID: '/api/v1/consult/getUserConsultById',
  CONSULTATION_ADD_TOPIC: '/api/v1/consult/addConsultTopic',
  CONSULTATION_EDIT_TOPIC: '/api/v1/consult/editConsultTopic',
  CONSULTATION_DELETE_TOPIC: '/api/v1/consult/deleteConsultTopic',
  CONSULTATION_GET_ALL_TOPICS: '/api/v1/consult/getAllConsultTopic',
  CONSULTATION_GET_ALL: '/api/v1/consult/getAllConsult',
  CONSULTATION_GET_BY_ID: '/api/v1/consult/getConsultById',
  CONSULTATION_GET_CONTACT: '/api/v1/consult/getContactConsultById',
  CONSULTATION_STAFF_REQUEST: '/api/v1/consult/staffRequestConsultation',
  CONSULTATION_EDIT: '/api/v1/consult/editConsultById',
  CONSULTATION_ADD_LOG: '/api/v1/consult/addConsultationLog',
  CONSULTATION_EDIT_LOG: '/api/v1/consult/editConsultationLog',
  CONSULTATION_ADD_FILE: '/api/v1/consult/addConsultationFile',
  CONSULTATION_ASSIGN: '/api/v1/consult/assignConsult',
  CONSULTATION_GET_STAFF: '/api/v1/consult/getStaffConsult',
  CONSULTATION_DELETE: '/api/v1/consult/deleteConsult',

  /* Knowledge */
  // Public endpoints
  KNOWLEDGE_GET_RECORDS: '/api/v1/public/knowledge/getKnowledgeRecords',
  KNOWLEDGE_GET_LAST: '/api/v1/public/knowledge/getLastKnowledge',
  KNOWLEDGE_BY_ID: '/api/v1/public/knowledge/getKnowledgeById',
  KNOWLEDGE_GET_BY_DATE: '/api/v1/public/knowledge/getKnowledgeByDate',
  KNOWLEDGE_SEARCH: '/api/v1/public/knowledge/searchKnowledge',
  
  // Admin/Staff endpoints
  KNOWLEDGE_ADD: '/api/v1/knowledge/addKnowledge',
  KNOWLEDGE_EDIT: '/api/v1/knowledge/editKnowledge',
  KNOWLEDGE_DELETE: '/api/v1/knowledge/deleteKnowledge',
  KNOWLEDGE_UPDATE_STATUS: '/api/v1/knowledge/updateKnowledgeStatus',
  KNOWLEDGE_ADD_DOC: '/api/v1/knowledge/addKnowledgeDoc',
  KNOWLEDGE_DELETE_DOC: '/api/v1/knowledge/deleteKnowledgeDoc',

  /* FAQ */
  FAQ_LIST: '/api/v1/faq/getFAQs',
  FAQ_BY_ID: '/api/v1/faq/getFaqByID',
  FAQ_UPDATE_STATUS: '/api/v1/faq/updateStatusFaq',
  FAQ_ADD: '/api/v1/faq/addFaq',
  FAQ_EDIT: '/api/v1/faq/editFaq',
  FAQ_DELETE: '/api/v1/faq/deleteFaq',

  /* Evaluation */
  // Public endpoints
  EVALUATION_GET_LIST: '/api/v1/evaluation/getListTemplate',
  EVALUATION_GET_TEMPLATE: '/api/v1/evaluation/template',
  EVALUATION_GET_QUESTIONS: '/api/v1/evaluation/getQuestionsByTemplateId/:id',
  EVALUATION_LIST_SCORES: '/api/v1/evaluation/listScores/:id',
  EVALUATION_GET_DETAILS: '/api/v1/evaluation/getEvaluationDetails/:user_id/:id',
  
  // Admin/Staff endpoints
  EVALUATION_ADD_SCORE: '/api/v1/evaluation/evaluation-score',
  EVALUATION_ADD: '/api/v1/evaluation/addEvaluation',
  EVALUATION_ADD_QUESTIONS: '/api/v1/evaluation/addQuestions',
  EVALUATION_EDIT: '/api/v1/evaluation/editEvaluation',
  EVALUATION_UPDATE_STATUS: '/api/v1/evaluation/updateStatusEvaluation',
  EVALUATION_DELETE_QUESTIONS: '/api/v1/evaluation/deleteQuestions/:id',
  EVALUATION_DELETE: '/api/v1/evaluation/deleteEvaluation/:id',

  /* Users */
  USERS_REGISTER: '/api/v1/users/register',
  USERS_GET_PROFILE: '/api/v1/users/getProfile',
  USERS_SET_PROFILE: '/api/v1/users/setProfile',
  USERS_SET_ADDRESS: '/api/v1/users/setAddress',
  USERS_CHANGE_PASSWORD: '/api/v1/users/changePassword',
  USERS_GET_ALL: '/api/v1/users/getAllUsers',
  USERS_GET_STAFF_DETAILS: '/api/v1/users/getStaffDetails',
  USERS_ADD_STAFF: '/api/v1/users/addStaff',
  USERS_UPDATE_STAFF: '/api/v1/users/updateStaff',
  USERS_DELETE: '/api/v1/users/deleteUser',
  USERS_ADD_ROLE: '/api/v1/users/addRole',
  USERS_UPDATE_ROLE: '/api/v1/users/editRole',
  USERS_DEL_ROLE: '/api/v1/users/deleteRole',
  USERS_UPDATE_ROLE_PERMISSIONS: '/api/v1/users/updateRolePermissions',
  USERS_GET_PROVINCE_DISTRICTS: '/api/v1/users/getProvinceDistrictsSubdistricts',
  USERS_GET_LIST_PERMISSIONS: '/api/v1/users/getListPermission',
  USERS_GET_LIST_RULE: '/api/v1/users/getRoleList',
  /* User Shortcuts */
  SET_USER_PROFILE: '/api/v1/users/setProfile',
  SET_USER_ADDRESS: '/api/v1/users/setAddress',
  SET_USER_CHANGE_PASS: '/api/v1/users/changeUserPassword',
  GET_ADDR_BY_CODE: '/api/v1/users/getProvinceDistrictsSubdistricts',

  /* File Management */
  FILE_DOWNLOAD: '/api/v1/public/download',
  CALLBACK: '/api/v1/public/callback',

  /* Legacy/Deprecated */
  REFRESH_TOKEN: '/refreshToken',
  REG_CREATE: '/registerCreateUser',
  REG_OTP: '/registerSendOTP',
  REG_VERIFY_OTP: '/registerVerifyOtp',
  PROFILE: '/api/v1/users/getProfile',
  PROFIL_UPDATE: '/user/profile/update',
  USER_FORGET_PASS: '/user/forget_password',
  USER_VERIFY_OTP: '/user/verifyOTPPin',
  USER_NEW_PASSWORD: '/user/submitNewPassword',

  /* Test Endpoints */
  TEST_SEND_MAIL: '/api/v1/auth/testSendMail',

  /* XAPI */
  XAPI_CREATE: '/api/v1/xapi/createXapi',
  XAPI_GET_LIST: '/api/v1/xapi/getListXapi',
  SET_XAPI_LOGIN: '/api/v1/xapi/loginXapi',


  /* International Cooperation */
  INTL_COOP_LIST: '/api/v1/intl_coop/list',
  INTL_COOP_VIEW: '/api/v1/intl_coop/view',
  INTL_COOP_INSERT: '/api/v1/intl_coop/insert',
  INTL_COOP_EDIT: '/api/v1/intl_coop/edit',
  
  // Category endpoints
  INTL_COOP_ADD_CATEGORY: '/api/v1/intl_coop/addCategory',
  INTL_COOP_EDIT_CATEGORY: '/api/v1/intl_coop/editCategory',
  INTL_COOP_GET_CATEGORY: '/api/v1/intl_coop/getCategory',
  INTL_COOP_DELETE_CATEGORY: '/api/v1/intl_coop/deleteCategory',
  
  // Type endpoints
  INTL_COOP_ADD_TYPE: '/api/v1/intl_coop/addType',
  INTL_COOP_EDIT_TYPE: '/api/v1/intl_coop/editType',
  INTL_COOP_GET_TYPE: '/api/v1/intl_coop/getType',
  INTL_COOP_DELETE_TYPE: '/api/v1/intl_coop/deleteType',
  
  // File management
  INTL_COOP_INSERT_FILE: '/api/v1/intl_coop/insertFile',
  INTL_COOP_DELETE_FILE: '/api/v1/intl_coop/deleteFile',
  
  // Log management
  INTL_COOP_INSERT_LOG: '/api/v1/intl_coop/insertLog',
  INTL_COOP_EDIT_LOG: '/api/v1/intl_coop/editLog',
  
  // Staff/Assignment management  
  INTL_COOP_GET_STAFF: '/api/v1/intl_coop/getStaff',
  INTL_COOP_ASSIGN: '/api/v1/intl_coop/assign',
  INTL_COOP_DELETE_ASSIGN: '/api/v1/intl_coop/deleteAssign',


  /* Regulation */
  REGULATION_GET_LIST: '/api/v1/regulation/list',
  REGULATION_VIEW: '/api/v1/regulation/view',
  REGULATION_INSERT: '/api/v1/regulation/insert',
  REGULATION_EDIT: '/api/v1/regulation/edit',

  // Regulation Categories
  REGULATION_ADD_CATEGORY: '/api/v1/regulation/addCategory',
  REGULATION_EDIT_CATEGORY: '/api/v1/regulation/editCategory',
  REGULATION_GET_CATEGORY: '/api/v1/regulation/getCategory',
  REGULATION_DELETE_CATEGORY: '/api/v1/regulation/deleteCategory',

  // Regulation Types
  REGULATION_ADD_TYPE: '/api/v1/regulation/addType',
  REGULATION_EDIT_TYPE: '/api/v1/regulation/editType',
  REGULATION_GET_TYPE: '/api/v1/regulation/getType',
  REGULATION_DELETE_TYPE: '/api/v1/regulation/deleteType',

  // Regulation Files
  REGULATION_INSERT_FILE: '/api/v1/regulation/insertFile',
  REGULATION_DELETE_FILE: '/api/v1/regulation/deleteFile',

  // Regulation Logs
  REGULATION_INSERT_LOG: '/api/v1/regulation/insertLog',
  REGULATION_EDIT_LOG: '/api/v1/regulation/editLog',

  // Regulation Staff/Assignment
  REGULATION_GET_STAFF: '/api/v1/regulation/getStaff',
  REGULATION_ASSIGN: '/api/v1/regulation/assign',
  REGULATION_DELETE_ASSIGN: '/api/v1/regulation/deleteAssign',

  /* Legal Case Management */
  // Main CRUD endpoints
  LEGAL_CASE_GET_LIST: '/api/v1/legal_case/list',
  LEGAL_CASE_VIEW: '/api/v1/legal_case/view',
  LEGAL_CASE_INSERT: '/api/v1/legal_case/insert',
  LEGAL_CASE_EDIT: '/api/v1/legal_case/edit',

  // Category endpoints
  LEGAL_CASE_ADD_CATEGORY: '/api/v1/legal_case/addCategory',
  LEGAL_CASE_EDIT_CATEGORY: '/api/v1/legal_case/editCategory', 
  LEGAL_CASE_GET_CATEGORY: '/api/v1/legal_case/getCategory',
  LEGAL_CASE_DELETE_CATEGORY: '/api/v1/legal_case/deleteCategory',

  // Type endpoints
  LEGAL_CASE_ADD_TYPE: '/api/v1/legal_case/addType',
  LEGAL_CASE_EDIT_TYPE: '/api/v1/legal_case/editType',
  LEGAL_CASE_GET_TYPE: '/api/v1/legal_case/getType',
  LEGAL_CASE_DELETE_TYPE: '/api/v1/legal_case/deleteType',

  // File management
  LEGAL_CASE_INSERT_FILE: '/api/v1/legal_case/insertFile',
  LEGAL_CASE_DELETE_FILE: '/api/v1/legal_case/deleteFile',

  // Log management
  LEGAL_CASE_INSERT_LOG: '/api/v1/legal_case/insertLog', 
  LEGAL_CASE_EDIT_LOG: '/api/v1/legal_case/editLog',

  // Staff/Assignment management
  LEGAL_CASE_GET_STAFF: '/api/v1/legal_case/getStaff',
  LEGAL_CASE_ASSIGN: '/api/v1/legal_case/assign',
  LEGAL_CASE_DELETE_ASSIGN: '/api/v1/legal_case/deleteAssign',

  // logging
  LOGGING_GET_BY_ID: '/api/v1/logging/getLoggingById',
  LOGGING_LIST: '/api/v1/logging/listLogging'
}
