import Vue from 'vue'
import Vuex from 'vuex'
import {
  homeModule,
  authModule,
  registerModule,
  errorModule,
  knowledgeModule,
  faqModule,
  newsModule,
  consultationModule,
  consultationTypeModule,
  usersModule,
  litigationModule,
  contractualActModule,
  consultationAssignModule,
  evaluationModule,
  xapiModule,
  intlCoopModule,
  regulationModule,
  legalCaseModule,
  loggingModule
} from '@/store/modules'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homeModule,
    authModule,
    registerModule,
    errorModule,
    knowledgeModule,
    faqModule,
    newsModule,
    consultationModule,
    consultationTypeModule,
    usersModule,
    litigationModule,
    contractualActModule,
    consultationAssignModule,
    evaluationModule,
    xapiModule,
    intlCoopModule,
    regulationModule,
    legalCaseModule,
    loggingModule
  }
})
