import {
  SET_EVALUATION_TEMPLATES,
  SET_EVALUATION_QUESTIONS,
  SET_EVALUATION_SCORES,
  SET_EVALUATION_DETAILS,
  SET_EVALUATION_LOADING,
  SET_CURRENT_TEMPLATE,
  SET_ERROR,
  DELETE_EVALUATION_QUESTIONS,
  DELETE_EVALUATION,
  ADD_EVALUATION
} from '@/constants'

import {
  addEvaluationScoreService,
  addEvaluationService,
  addEvaluationQuestionsService,
  getQuestionsByTemplateIdService,
  listScoresByTemplateService,
  getEvaluationDetailsService,
  getEvaluationTemplateService,
  getListTemplateService,
  editEvaluationService,
  updateEvaluationStatusService,
  deleteEvaluationQuestionsService,
  deleteEvaluationService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const evaluationModule = {
  state: {
    templates: [],           // List of all evaluation templates
    currentTemplate: null,   // Currently selected template
    questions: [],          // Questions for current template
    scores: [],            // Evaluation scores
    details: null,         // Detailed evaluation data
    loading: false,        // Loading state
    error: null           // Error state
  },

  getters: {
    evaluationTemplates: state => state.templates,
    currentTemplate: state => state.currentTemplate,
    evaluationQuestions: state => state.questions,
    evaluationScores: state => state.scores,
    evaluationDetails: state => state.details,
    isLoading: state => state.loading,
    hasError: state => !!state.error,
    errorMessage: state => state.error
  },

  mutations: {
    [SET_EVALUATION_TEMPLATES]: (state, payload) => {
      state.templates = payload
    },
    [SET_CURRENT_TEMPLATE]: (state, payload) => {
      state.currentTemplate = payload
    },
    [SET_EVALUATION_QUESTIONS]: (state, payload) => {
      state.questions = payload
    },
    [SET_EVALUATION_SCORES]: (state, payload) => {
      state.scores = payload
    },
    [SET_EVALUATION_DETAILS]: (state, payload) => {
      state.details = payload
    },
    [SET_EVALUATION_LOADING]: (state, payload) => {
      state.loading = payload
    },
    [SET_ERROR]: (state, payload) => {
      state.error = payload
    },
    [DELETE_EVALUATION_QUESTIONS]: () => {},
    [DELETE_EVALUATION]: () => {}
  },

  actions: {
    // Get list of all evaluation templates
    getListTemplate: async ({ commit }) => {
      try {
        commit(SET_EVALUATION_TEMPLATES, [])
        const resp = await getListTemplateService()
        console.log('getListTemplate')
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_TEMPLATES, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Submit evaluation score
    addEvaluationScore: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await addEvaluationScoreService(data)
        if (isSuccess(resp.status)) {
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Create new evaluation template
    addEvaluation: async ({ commit }, data) => {
      try {

        const resp = await addEvaluationService(data)
        if (isSuccess(resp.status)) {
          commit(ADD_EVALUATION, true)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(ADD_EVALUATION)
      }
    },

    // Add questions to template
    addEvaluationQuestions: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await addEvaluationQuestionsService(data)
        if (isSuccess(resp.status)) {
          await commit(SET_EVALUATION_QUESTIONS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Get questions for specific template
    getQuestionsByTemplateId: async ({ commit }, id) => {
      try {
        const resp = await getQuestionsByTemplateIdService(id)
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_QUESTIONS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      }
    },

    // List scores by template
    listScoresByTemplate: async ({ commit }, { id, params }) => {
      try {
        commit(SET_EVALUATION_SCORES, [])
        const resp = await listScoresByTemplateService(id, params)
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_SCORES, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Get evaluation details
    getEvaluationDetails: async ({ commit }, { userId, templateId }) => {
      try {
        commit(SET_EVALUATION_DETAILS, [])
        const resp = await getEvaluationDetailsService(userId, templateId)
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_DETAILS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Edit evaluation template
    editEvaluation: async ({ commit, dispatch }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await editEvaluationService(data)
        if (isSuccess(resp.status)) {
          await dispatch('getListTemplate')  // Refresh template list
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Update evaluation status
    updateEvaluationStatus: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await updateEvaluationStatusService(data)
        return isSuccess(resp.status)
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Delete evaluation questions
    deleteEvaluationQuestions: async ({ commit }, questionId) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await deleteEvaluationQuestionsService(questionId)
        if (isSuccess(resp.status)) {
          commit(DELETE_EVALUATION_QUESTIONS)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Delete evaluation template
    deleteEvaluation: async ({ commit }, templateId) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await deleteEvaluationService(templateId)
        if (isSuccess(resp.status)) {
          commit(DELETE_EVALUATION)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    getEvaluationTemplate: async ({ commit }, id) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await getEvaluationTemplateService(id)
        if (isSuccess(resp.status)) {
          commit(SET_CURRENT_TEMPLATE, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Add convenience action to load template with questions
    getTemplateWithQuestions: async ({ dispatch }, id) => {
      try {
        const templateResult = await dispatch('getEvaluationTemplate', id)
        if (templateResult) {
          const questionsResult = await dispatch('getQuestionsByTemplateId', id)
          return questionsResult
        }
        return false
      } catch (error) {
        return false
      }
    },

    // Clear error state
    clearError: ({ commit }) => {
      commit(SET_ERROR, null)
    }
  }
}

export { evaluationModule }