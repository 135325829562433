// litigation-module.js
import {
  SET_LITIGATION_LIST,
  SET_LITIGATION_BY_ID,
  SET_LITIGATION_STATUS,
  SET_LITIGATION,
  SET_UPDATE_LITIGATION,
  SET_ADD_LITIGATION,
  LITIGATION_DELETE,
  SET_LITIGATION_TYPE_LIST,
  LITIGATION_TYPE_DELETE,
  SET_ADD_LITIGATION_TYPE,
  SET_UPDATE_LITIGATION_TYPE,
  SET_LITIGATION_SUM_LIST,
  SET_ADD_LITIGATION_FILE,
  SET_DEL_LITIGATION_FILE,

  SET_UPDATE_LITIGATION_LOG,
  SET_ADD_LITIGATION_LOG,
  SET_LITIGATION_STAFF_LIST,
  SET_LITIGATION_ACTIVE
} from '@/constants'

import {
  getLitigationListService,
  editLitigationService,
  getLitigationByIdService,
  updateStatusLitigationService,
  addLitigationServices,
  deleteLitigationService,
  getCaseTypeListService,
  addLitigationTypeServices,
  editLitigationTypeService,
  deleteLitigationTypeService,
  getLitigationSumListService,
  addLitigationFileServices,
  delLitigationFileServices,
  addLitigationLogServices,
  editLitigationLogService,
  delLitigationLogService,
  getLitigationStaffService,
  assignCaseService,
  deleteCaseAssignService

} from '@/services'

import { isSuccess } from '@/utils/statusCheck'
const litigationModule = {
  state: {
    litigationList: [],
    litigationTypeList: [],
    litigationById: {},
    litigationSumList: [],
    litigationDetail: [],
    litigationStaffList: [],
  },

  getters: {
    litigationList: (state) => state.litigationList,
    litigationById: (state) => state.litigationById,
    litigationTypeList: (state) => state.litigationTypeList,
    litigationSumList: (state) => state.litigationSumList,
    litigationDetail: (state) => state.litigationDetail,
    litigationStaffList: (state) => state.litigationStaffList,
  },

  mutations: {
    [SET_LITIGATION_LIST]: (state, payload) => {
      state.litigationList = payload
    },
    [SET_LITIGATION_STAFF_LIST]: (state, payload) => {
      state.litigationStaffList = payload
    },
    [SET_LITIGATION]: (state, payload) => {
      state.litigationById = payload
    },
    [SET_LITIGATION_BY_ID]: (state, payload) => {
      state.litigationDetail = payload
      // console.log(state.litigationDetail)
    },

    [SET_LITIGATION_STATUS]: () => { },
    [SET_UPDATE_LITIGATION]: () => { },
    [SET_ADD_LITIGATION]: () => { },
    [LITIGATION_DELETE]: () => { },
    [SET_ADD_LITIGATION_FILE]: () => { },
    [SET_DEL_LITIGATION_FILE]: () => { },
    [SET_LITIGATION_TYPE_LIST]: (state, payload) => {
      state.litigationTypeList = payload
    },
    [SET_UPDATE_LITIGATION_TYPE]: () => { },
    [SET_ADD_LITIGATION_TYPE]: () => { },
    [LITIGATION_TYPE_DELETE]: () => { },
    [SET_LITIGATION_SUM_LIST]: (state, payload) => {
      state.litigationSumList = payload
    },
    [SET_UPDATE_LITIGATION_LOG]: () => { },
    [SET_ADD_LITIGATION_LOG]: () => { },

  },

  actions: {
    getLitigationStaff: async ({ commit }) => {
      // alert('getLitigationStaff');
      try {
        const resp = await getLitigationStaffService()
        if (isSuccess(resp.status)) {
          commit(SET_LITIGATION_STAFF_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting litigation list:', error)
        return false
      }
    },

    assignLitigation: async ({ commit }, data) => {
      try {
        const resp = await assignCaseService(data)
        commit(SET_LITIGATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error assigning staff:', error)
        return false
      }
    },

    deleteLitigationAssign: async ({ commit }, data) => {
      try {
        const resp = await deleteCaseAssignService(data)
        commit(SET_LITIGATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting assignment:', error)
        return false
      }
    },
    getLitigationListApi: async ({ commit }, params = {}) => {
      try {
        const resp = await getLitigationListService(params)
        if (isSuccess(resp.status)) {
          commit(SET_LITIGATION_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting litigation list:', error)
        return false
      }
    },

    getLitigationIDApi: async ({ commit }, params = {}) => {
      try {
        // alert(params)
        const resp = await getLitigationByIdService(params)
        if (isSuccess(resp.status)) {
          commit(SET_LITIGATION_BY_ID, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting litigation:', error)
        return false
      }
    },

    updateLitigationStatus: async ({ commit }, { id, status }) => {
      try {
        const resp = await updateStatusLitigationService(id, status)
        if (isSuccess(resp.status)) {
          commit(SET_LITIGATION_STATUS)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating litigation status:', error)
        return false
      }
    },

    addLitigationApi: async ({ commit }, formData) => {
      try {
        const resp = await addLitigationServices(formData)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_LITIGATION)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding litigation:', error)
        return false
      }
    },

    updateLitigationApi: async ({ commit }, formData) => {
      try {
        const resp = await editLitigationService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_LITIGATION)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating litigation:', error)
        return false
      }
    },

    deleteLitigationApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteLitigationService({ case_id: id })
        if (isSuccess(resp.status)) {
          commit(LITIGATION_DELETE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting litigation:', error)
        return false
      }
    },
    async getLitigationTypeListApi({ commit }) {
      try {
        // alert('')
        const resp = await getCaseTypeListService()
        if (isSuccess(resp.status)) {
          commit(SET_LITIGATION_TYPE_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting case types:', error)
        return false
      }
    },
    addLitigationTypeApi: async ({ commit }, formData) => {
      try {
        const resp = await addLitigationTypeServices(formData)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_LITIGATION_TYPE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding litigation:', error)
        return false
      }
    },

    updateLitigationTypeApi: async ({ commit }, formData) => {
      try {
        const resp = await editLitigationTypeService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_LITIGATION_TYPE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating litigation:', error)
        return false
      }
    },
    deleteLitigationTypeApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteLitigationTypeService(id)
        if (isSuccess(resp.status)) {
          commit(LITIGATION_TYPE_DELETE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting litigation:', error)
        return false
      }
    },
    getLitigationSumListApi: async ({ commit }, params = {}) => {
      try {
        const resp = await getLitigationSumListService(params)
        if (isSuccess(resp.status)) {
          commit(SET_LITIGATION_SUM_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting litigation list:', error)
        return false
      }
    },
    // addLitigationFileServices
    addLitigationFileApi: async ({ commit }, formData) => {
      try {
        console.log(formData)
        const resp = await addLitigationFileServices(formData)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_LITIGATION_FILE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding litigation:', error)
        return false
      }
    },
    // delLitigationFileServices
    delLitigationFileApi: async ({ commit }, formData) => {
      try {
        console.log(formData)
        const resp = await delLitigationFileServices(formData)
        if (isSuccess(resp.status)) {
          commit(SET_DEL_LITIGATION_FILE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding litigation:', error)
        return false
      }
    },

    addLitigationLogApi: async ({ commit }, formData) => {
      try {
        const resp = await addLitigationLogServices(formData)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_LITIGATION)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding litigation:', error)
        return false 
      }
    },

    updateLitigationLogApi: async ({ commit }, formData) => {
      try {
        const resp = await editLitigationLogService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_LITIGATION)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating litigation:', error)
        return false
      }
    },
    delLitigationLogApi: async ({ commit }, formData) => {
      try {
        console.log('delLitigationLogApi ')
        console.log(formData)
        const resp = await delLitigationLogService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_LITIGATION)
          return true
        }
        return false
      } catch (error) {
        console.error('Error delete  litigation:', error)
        return false
      }
    },

  }
}

export {
  litigationModule
}