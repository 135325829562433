import axios from 'axios'
import { localStorageService } from './localStorage'
import { apiConstant } from '@/constants'
import store from '@/store'
import Vue from 'vue';

// import encryption from '@/utils/'
const API_ROOT = process.env.VUE_APP_API_URL
const TIMEOUT = 60000
const HEADERS = {
  'Content-Type': 'application/json'
}

export class ApiService {
  constructor ({ baseURL = API_ROOT, timeout = TIMEOUT, headers = HEADERS }) {
    const instance = axios.create({
      baseURL,
      timeout,
      headers,
      withCredentials: false
    })
    this.client = instance
    instance.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError
    )
    instance.interceptors.response.use(this.handleSuccess, this.handleError)
  }

  async handleRequest (request) {
    const token = localStorageService.getAccessToken()
    // if (request is encrypt === 'Y') {
    // request.data = { data: encryption.encrypt(request.data) }
    // }
    if (token) {
      request.headers.Authorization = token//'Bearer ' + token
    }
    return request
  }

  async handleRequestError (error) {
    localStorageService.clearToken()
    console.log('handleRequestError')
    window.location.reload()
    Promise.reject(error)
  }

  handleSuccess (response) {
    if (response.data.status_code !== 'SUCCESS') {
      store.dispatch('openErrorPopup', response.data.status_msg)
    }
    return response
  }

  async handleError (error) {
    console.log('handleError')
    console.log(error)
    const originalRequest = error.config
    if (error.response.status === 401 || error.response.status === 403) {
      originalRequest._retry = true
      if(!localStorageService.getRefreshToken()){
        window.location.href = "/";
      }
      const headerReToken = {
        'Content-Type': 'application/json',
        'Authorization': localStorageService.getRefreshToken()
      }


      return axios
        .create({
          baseURL: `${process.env.VUE_APP_API_URL}`,
          timeout: TIMEOUT,
          headers: headerReToken,
          withCredentials: false
        })
        .get(apiConstant.REFRESH_TOKEN, {})
        .then(async res => {
          console.log('---new token--')
          console.log(res)
          if (res?.data?.status !== 'success') {
            await localStorageService.clearToken()
            console.log('.then(async res => {')
            //window.location.reload()
            return
          }
          localStorageService.setTokenDefault(res?.data?.data?.accessToken)
          axios.defaults.headers.common.Authorization =
            localStorageService.getAccessToken()
          return await axios(originalRequest)
        })
        .catch(async err => {
          await localStorageService.clearToken()
          console.log('catch(async err ')
          //window.location.reload()
          Promise.reject(err)
        })
    }else if(error.response.status === 404){
      Vue.prototype.$toast.error('เกิดข้อผิดพลาด 404')
    }
    else{
      Vue.prototype.$toast.error(error?.response?.data?.msg)
    }
  }

  async get (path,payload) {
     // Convert payload to query string
    const queryString = new URLSearchParams(payload).toString();
    
    // Append query string to path
    const fullPath = queryString ? `${path}?${queryString}` : path;

    return this.client.get(fullPath).then(response => response?.data);
    // return this.client.get(path).then(response => response?.data)
  }

  async post (path, payload) {
    return this.client.post(path, payload).then(response => {
      return response?.data
    })
  }

  async put (path, payload) {
    return this.client.put(path, payload).then(response => response?.data)
  }

  async patch (path, payload) {
    return this.client.patch(path, payload).then(response => response?.data)
  }

  async delete (path) {
    return this.client.delete(path).then(response => response?.data)
  }
}
