export const STATUS_SUCCESS = 'success'
export const STATUS_OK = 'ok'

export const apiConstant2 = {

  LITIGATION_GET_RECORDS: '/api/v1/case/getListCases',
  LITIGATION_GET_LAST: '/api/v1/case/getLastKnowledge',  // Added missing endpoint
  LITIGATION_BY_ID: '/api/v1/case/getCaseById',
  LITIGATION_GET_BY_DATE: '/api/v1/case/getListCases',  // Added missing endpoint
  LITIGATION_SEARCH: '/api/v1/case/searchKnowledge',  // Added missing endpoint

  LITIGATION_ADD: '/api/v1/case/addCase',
  LITIGATION_EDIT: '/api/v1/case/editCase',
  LITIGATION_DELETE: '/api/v1/case/deleteCase',
  //  LITIGATION_UPDATE_STATUS: '/api/v1/case/updateKnowledgeStatus',

  LITIGATION_GET_TYPE: '/api/v1/case/getCaseType',
  LITIGATION_TYPE_BY_ID: '/api/v1/case/getCaseType',
  LITIGATION_ADD_TYPE: '/api/v1/case/addCaseType',
  LITIGATION_EDIT_TYPE: '/api/v1/case/editCaseType',
  LITIGATION_DELETE_TYPE: '/api/v1/case/deleteCaseType',
  LITIGATION_ADD_FILE: '/api/v1/case/addCaseFile',
  LITIGATION_DEL_FILE: '/api/v1/case/deleteCaseFile',

  LITIGATION_LOG_ADD: '/api/v1/case/addCaseLog',
  LITIGATION_LOG_EDIT: '/api/v1/case/editCaseLog',
  LITIGATION_LOG_DEL: '/api/v1/case/deleteCaseLog',
  LITIGATION_SUM_GET: '/api/v1/case/sumCase',
  // Staff/Assignment management
  LITIGATION_GET_STAFF: '/api/v1/case/getStaffCase',
  LITIGATION_ASSIGN: '/api/v1/case/caseAssign',
  LITIGATION_DELETE_ASSIGN: '/api/v1/case/deleteCaseAssign',


  /* Contractual Acts */
  // CONTRACTUAL_ACT_UPDATE_STATUS: '/api/v1/legal_case/updateActStatus',
  // CONTRACTUAL_ACT_DELETE: '/api/v1/legal_case/deleteAct',

  CONTRACTUAL_ACT_GET_RECORDS: '/api/v1/legal_case/list',
  CONTRACTUAL_ACT_BY_ID: '/api/v1/legal_case/view',
  CONTRACTUAL_ACT_ADD: '/api/v1/legal_case/insert',
  CONTRACTUAL_ACT_EDIT: '/api/v1/legal_case/edit',

  // File management
  CONTRACTUAL_ACT_ADD_FILE: '/api/v1/legal_case/insertFile',
  CONTRACTUAL_ACT_DEL_FILE: '/api/v1/legal_case/deleteFile',

  // Log management
  CONTRACTUAL_ACT_INSERT_LOG: '/api/v1/legal_case/insertLog',
  CONTRACTUAL_ACT_EDIT_LOG: '/api/v1/legal_case/editLog',

  // Staff/Assignment management
  CONTRACTUAL_ACT_GET_STAFF: '/api/v1/legal_case/getStaff',
  CONTRACTUAL_ACT_ASSIGN: '/api/v1/legal_case/assign',
  CONTRACTUAL_ACT_DELETE_ASSIGN: '/api/v1/legal_case/deleteAssign'
}

// Optional: Add status constants for consistent use across the application
export const API_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed',
  PUBLISHED: 'published',
  DRAFT: 'draft'
}

// Optional: Add user types for consistent use
export const USER_TYPES = {
  CUSTOMER: 1,
  STAFF: 2
}

// Optional: Add consultation status types
export const CONSULTATION_STATUS = {
  PENDING: 'รอการพิจารณา',
  IN_REVIEW: 'นำเข้าพิจารณา',
  IN_PROGRESS: 'สืบเนื่อง',
  COMPLETED: 'ดำเนินการเสร็จสิ้น'
}

// Optional: Add common response messages
export const RESPONSE_MESSAGES = {
  SUCCESS: 'ดำเนินการสำเร็จ',
  ERROR: 'เกิดข้อผิดพลาด',
  UNAUTHORIZED: 'ไม่มีสิทธิ์เข้าถึง',
  INVALID_DATA: 'ข้อมูลไม่ถูกต้อง'
}
