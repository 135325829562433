import { SET_FAQLIST, SET_FAQ_STATUS, SET_FAQ, SET_UPDATE_FAQ, SET_ADD_FAQ } from '@/constants'
import { getFaqListService, editFaqService, getFaqByIdService, updateStatusFaqService, addFaqService, deleteFaqService } from '@/services'
import { isSuccess } from '@/utils/statusCheck'; // Status check utility

const faqModule = {
  state: {
    faqList: [],
    faq: null
  },
  getters: {
    faqList: (state) => state.faqList,
    faq: (state) => state.faq
  },
  mutations: {
    [SET_FAQLIST]: (state, payload) => {
      state.faqList = payload
    },
    [SET_FAQ]: (state, payload) => {
      state.faq = payload
    },
  },
  actions: {
    getFaqListApi: async ({ commit }, { limit }) => {
      try {
        const resp = await getFaqListService(limit)
        if (isSuccess(resp.status)) {
          commit(SET_FAQLIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getFaqByIdApi: async ({ commit }, { id }) => {
      try {
        const resp = await getFaqByIdService(id)
        if (isSuccess(resp.status)) {
          commit(SET_FAQ, resp.data)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    updateStatusFaq: async ({ commit }, { id, status }) => {
      try {
        const resp = await updateStatusFaqService(id, status)
        if (isSuccess(resp.status)) {
          commit(SET_FAQ_STATUS)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    addFaqApi: async ({ commit }, { question, answer, status }) => {
      try {
        const resp = await addFaqService(question, answer, status)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_FAQ)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    updateFaqApi: async ({ commit }, { id, question, answer, status }) => {
      try {
        const resp = await editFaqService(id, question, answer, status)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_FAQ)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    deleteFaqApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteFaqService(id)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_FAQ)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    }
  }
}

export {
  faqModule
}
