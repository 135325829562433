// knowledge-module.js (Store)
import {
  SET_KNOWLEDGELIST,
  SET_KNOWLEDGE_STATUS,
  SET_KNOWLEDGE,
  SET_UPDATE_KNOWLEDGE,
  SET_ADD_KNOWLEDGE,
  KNOWLEDGE_DELETE,
  ADD_KNOWLEDGE_DOC,
  DEL_KNOWLEDGE_DOC
} from '@/constants'
import {
  getKnowledgeListService,
  editKnowledgeService,
  getKnowledgeByIdService,
  updateStatusKnowledgeService,
  addKnowledgeService,
  deleteKnowledgeService,
  addKnowledgeDocService,
  deleteKnowledgeDocService
} from '@/services'
import { isSuccess } from '@/utils/statusCheck'

const knowledgeModule = {
  state: {
    knowledgeList: [],
    knowledgeById: {}
  },

  getters: {
    knowledgeList: (state) => state.knowledgeList,
    knowledgeById: (state) => state.knowledgeById
  },

  mutations: {
    [SET_KNOWLEDGELIST]: (state, payload) => {
      state.knowledgeList = payload
    },
    [SET_KNOWLEDGE]: (state, payload) => {
      state.knowledgeById = payload
    },
    // [SET_KNOWLEDGE_STATUS]: (state) => {
    //   // Handle status change if needed
    // },
    // [SET_UPDATE_KNOWLEDGE]: (state) => {
    //   // Handle update if needed
    // },
    // [SET_ADD_KNOWLEDGE]: (state) => {
    //   // Handle add if needed
    // }
  },

  actions: {
    getKnowledgeListApi: async ({ commit }, params = {}) => {
      try {
        const resp = await getKnowledgeListService(params)
        if (isSuccess(resp.status)) {
          commit(SET_KNOWLEDGELIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    getKnowledgeApi: async ({ commit }, { id }) => {
      try {
        const resp = await getKnowledgeByIdService(id)
        if (isSuccess(resp.status)) {
          commit(SET_KNOWLEDGE, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    updateKnowledgeStatus: async ({ commit }, { id, status }) => {
      try {
        const resp = await updateStatusKnowledgeService(id, status)
        if (isSuccess(resp.status)) {
          commit(SET_KNOWLEDGE_STATUS)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    addKnowledgeApi: async ({ commit }, formData) => {
      try {
        const resp = await addKnowledgeService(formData);
        if (isSuccess(resp.status)) {
          commit(SET_ADD_KNOWLEDGE);
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
      return false;
    },
    updateKnowledgeApi: async ({ commit }, formData ) => {
      try {
        console.log('updateKnowledgeApi')
        console.log(formData)
        const resp = await editKnowledgeService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_KNOWLEDGE)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },

    deleteKnowledgeApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteKnowledgeService(id)
        if (isSuccess(resp.status)) {
          commit(KNOWLEDGE_DELETE)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    addKnowledgeDocApi: async ({ commit }, formData) => {
      try {
        const resp = await addKnowledgeDocService(formData);
        if (isSuccess(resp.status)) {
          commit(ADD_KNOWLEDGE_DOC)
          return true;
        }
      } catch (error) {
        console.error('Error adding document:', error);
        return false;
      }
      return false;
    },
  
    deleteKnowledgeDocApi: async ({ commit }, {knowledge_id, file_path}) => {
      try {
        const resp = await deleteKnowledgeDocService(knowledge_id, file_path);
        if (isSuccess(resp.status)) {
          commit(DEL_KNOWLEDGE_DOC)
          return true;
        }
      } catch (error) {
        console.error('Error deleting document:', error);
        return false;
      }
      return false;
    }

  }
}

export {
  knowledgeModule
}
